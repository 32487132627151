<template>
  <b-card-body :class="{'loading': loading}">
    <auction-category-table
      :data="data"
      :total="total"
      :period="filter.period"
    />
  </b-card-body>
</template>

<script>
import AuctionCategoryTable from './AuctionCategoryTable';
import {customersContractorsInAuctionsStatistics} from '@/services/api';
export default {
  name: 'AuctionCategory',
  components: {
    AuctionCategoryTable,
  },
  props: {
    filter: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      data: [],
      total: {},
      loading: true,
    };
  },
  watch: {
    filter: {
      handler: function(newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal);
        this.customersDateAuctionStatisticsChartsData(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.customersDateAuctionStatisticsChartsData(this.filter);
  },
  methods: {
    async customersDateAuctionStatisticsChartsData(data) {
      this.loading = true;
      const response = await customersContractorsInAuctionsStatistics(this.$store.state.user.ownerId, Object.assign(data));
      if (response && response.status === 200) {
        this.total= response.data.total;
        this.data = response.data.items.map((item)=>{
          return {...item, ...{profit: parseInt(item.profit)}};
        });
      };
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
